<script>
import { SuawInputGroup, SuawHeading, SuawEmptyState } from "@suaw/suaw-component-library";
import { GetUserViewForAdminLogins, GetUserLoginsForAdmin } from "../../operations.gql";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewLogins",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserView: {
        id: "",
        email: ""
      },
      resultUserLogins: [
        // {
        //   id: "",
        //   happened_at: "",
        //   error_json: ""
        //   ip_address: ""
        //   user_agent: ""
        // }
      ]
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminLogins,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultUserLogins: {
      query: GetUserLoginsForAdmin,
      variables() {
        return {
          email: this.resultUserView.email
        };
      },
      skip() {
        return this.isLoading || !this.resultUserView.email;
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    errorReason(errorJson) {
      //!!don't currently know why errors array would be empty so for now just handling both cases
      // {
      //   "errors":
      //   [
      //     {"__type":"Suaw.Domain.Error, Suaw.Domain","errors":[],"reason":"Invalid Login","message":"Password: Invalid Login.","exceptionType":"ValidationException"}
      //   ],
      //   "reason":"Invalid Argument(s)",
      //   "message":"Validation failed: \n -- Password: Invalid Login. Severity: Error\n -- : (Ignore Me) Severity: Error",
      //   "exceptionType":"ValidationException"
      // }
      // {
      //   "errors": [],
      //   "message":"23505: duplicate key value violates unique constraint \"user_tokens_pkey\"\n\nDETAIL: Detail redacted as it may contain sensitive data. Specify 'Include Error Detail' in the connection string to include this information.",
      //   "stackTrace":"   at ServiceStack.OrmLite.OrmLiteExecFilter.Exec[T](IDbConnection dbConn, Func`2 filter) in /home/runner/work/ServiceStack/ServiceStack/ServiceStack.OrmLite/src/ServiceStack.OrmLite/OrmLiteExecFilter.cs:line 137\n   at Suaw.Infrastructure.IDbConnectionExtensions.InsertResultAsync[T](IDbConnection dbConn, T obj) in /src/Suaw.Infrastructure/Extensions/IDbConnectionExtensions.cs:line 590",
      //   "exceptionType":"PostgresException"
      // }
      if (errorJson === null) {
        return "";
      }
      if (errorJson.errors.length === 0) {
        return errorJson.message;
      } else {
        return errorJson.errors[0].message;
      }
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawHeading content="Logins / Attempts" level="5" />
    <table>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Error</th>
        <th>IP Address</th>
        <th>User Agent</th>
      </tr>
      <tr v-for="item in resultUserLogins" :key="item.id">
        <td>{{ item.id }}</td>
        <td>{{ formatDateAt(item.happened_at) }}</td>
        <td>{{ errorReason(item.error_json) }}</td>
        <td>{{ item.ip_address }}</td>
        <td>{{ item.user_agent }}</td>
      </tr>
    </table>
  </SuawInputGroup>
</template>

<style lang="css" scoped>
table {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid black;
  padding: 0.5rem;
}
/* tr:nth-child(even) {
  background-color: #f2f2f2; // Background color for even rows
} */
</style>
