<script>
import { SuawSection, SuawInputGroup, SuawHeading, SuawAvatar, SuawButton, SuawAlert, SuawParagraph, SuawEmptyState } from "@suaw/suaw-component-library";
import { GetUserViewForAdminBasic } from "../../operations.gql";
import * as UserApi from "../../api.js";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewBasic",
  components: {
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SuawAvatar,
    SuawButton,
    SuawAlert,
    SuawParagraph,
    SuawEmptyState
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //Basic
      loadingQueriesCount: 0,
      resultUserView: {
        avatar_color: "",
        avatar_url: "",
        country: "",
        created_at: "",
        deleted_at: "",
        display_name: "",
        email: "",
        email_verified: false,
        first_name: "",
        initials: "",
        role: "",
        id: "",
        last_name: "",
        is_last_name_hidden: false,
        languages: "",
        description_json: {},
        unit_system: "",
        postal_code: ""
      }
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminBasic,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    //Basic Info
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    },
    avatarColor() {
      return this.resultUserView.avatar_color ? this.resultUserView.avatar_color : "blue";
    },
    // avatarType() {
    //   return !this.resultUserView.is_avatar_hidden && this.resultUserView.avatar_url ? "picture" : this.avatarColor;
    // },
    // avatarContent() {
    //   return !this.resultUserView.is_avatar_hidden && this.resultUserView.avatar_url ? this.resultUserView.avatar_url : this.resultUserView.initials;
    // },
    createdAt() {
      return this.formatDateAt(this.resultUserView.created_at);
    },
    isDeactivated() {
      return !!this.resultUserView.deleted_at;
    },
    isVerifiedText() {
      return this.resultUserView.email_verified ? "Yes" : "No";
    },
    lastNameHiddenText() {
      return this.resultUserView.is_last_name_hidden ? "Yes" : "No";
    },
    avatarHiddenText() {
      return this.resultUserView.is_avatar_hidden ? "Yes" : "No";
    },
    deactivatedAlertMessage() {
      return `This user was deactivated on ${new Date(this.resultUserView.deleted_at)}`;
    },
    role() {
      return this.resultUserView.role === "app_admin" ? "Admin" : this.resultUserView.role === "organizer" ? "Organizer" : "Writer";
    },
    lastLogin() {
      if (this.resultUserView.user_logins.length === 0) {
        return "";
      }
      return this.formatDateAt(this.resultUserView.user_logins[0].happened_at);
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    async hideAvatar() {
      const result = await UserApi.hideAvatar(this.resultUserView.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onHideAvatarClick() {
      const result = await this.hideAvatar();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "Avatar hidden.");
    },
    async unhideAvatar() {
      const result = await UserApi.unhideAvatar(this.resultUserView.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onUnhideAvatarClick() {
      const result = await this.unhideAvatar();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "Avatar un-hidden.");
    },
    async deactivateUser() {
      const result = await UserApi.deactivateUser(this.resultUserView.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onDeactivateUserClick() {
      const result = await this.deactivateUser();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "User deactivated.");
    },
    async reactivateUser() {
      const result = await UserApi.reactivateUser(this.resultUserView.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onReactivateUserClick() {
      const result = await this.reactivateUser();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "User reactivated.");
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawAlert v-if="isDeactivated" :message="deactivatedAlertMessage" type="critical" />
    <SuawSection>
      <SuawHeading content="Avatar" level="5" />
      <SuawButton
        v-if="!resultUserView.is_avatar_hidden"
        icon-left="IconMinus"
        button-text="Hide"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onHideAvatarClick"
      ></SuawButton>
      <SuawButton
        v-else
        icon-left="IconPlus"
        button-text="Unhide"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onUnhideAvatarClick"
      ></SuawButton>
    </SuawSection>
    <table>
      <tr>
        <td width="140px"><strong>Avatar Hidden?</strong></td>
        <td>{{ avatarHiddenText }}</td>
      </tr>
    </table>
    <SuawSection>
      <SuawAvatar type="picture" size="huge" :content="resultUserView.avatar_url" />
      <SuawAvatar :type="avatarColor" size="huge" :content="resultUserView.initials" />
    </SuawSection>
    <SuawSection>
      <SuawHeading content="Profile" level="5" />
      <SuawButton
        v-if="!isDeactivated"
        icon-left="IconMinus"
        button-text="Deactivate"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onDeactivateUserClick"
      ></SuawButton>
      <SuawButton
        v-else
        icon-left="IconPlus"
        button-text="Reactivate"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onReactivateUserClick"
      ></SuawButton>
    </SuawSection>
    <table>
      <tr>
        <td width="140px"><strong>Role</strong></td>
        <td>{{ role }}</td>
      </tr>
      <tr>
        <td><strong>First Name</strong></td>
        <td>{{ resultUserView.first_name }}</td>
      </tr>
      <tr>
        <td><strong>Last Name</strong></td>
        <td>{{ resultUserView.last_name }}</td>
      </tr>
      <tr>
        <td><strong>Last Name Hidden?</strong></td>
        <td>{{ lastNameHiddenText }}</td>
      </tr>
      <tr>
        <td><strong>Display Name</strong></td>
        <td>{{ resultUserView.display_name }}</td>
      </tr>
      <tr>
        <td><strong>Email</strong></td>
        <td>{{ resultUserView.email }}</td>
      </tr>
      <tr>
        <td><strong>Email Verified?</strong></td>
        <td>{{ isVerifiedText }}</td>
      </tr>
      <tr>
        <td><strong>Country</strong></td>
        <td>{{ resultUserView.country }}</td>
      </tr>
      <tr>
        <td><strong>Postal Code</strong></td>
        <td>{{ resultUserView.postal_code }}</td>
      </tr>
      <tr>
        <td><strong>Unit System</strong></td>
        <td>{{ resultUserView.unit_system }}</td>
      </tr>
      <tr>
        <td><strong>Last Login</strong></td>
        <td>{{ lastLogin }}</td>
      </tr>
      <tr>
        <td><strong>Created</strong></td>
        <td>{{ createdAt }}</td>
      </tr>
    </table>
    <SuawHeading content="About" level="5" />
    <SuawSection section-style="border">
      <SuawParagraph :text="resultUserView.description_json" />
    </SuawSection>
  </SuawInputGroup>
</template>

<style lang="css" scoped>
</style>
