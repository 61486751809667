<script>
import { SuawSection, SuawInputGroup, SuawHeading, SuawEmptyState, SuawButton, SuawTable, SuawTableItem, SuawTextInput } from "@suaw/suaw-component-library";
import { GetUserViewForAdminChapterMembers } from "../../operations.gql";
import { ChapterListForUserAdmin } from "@/features/chapters/operations.gql";
import * as ChapterApi from "@/features/chapters/api.js";

export default {
  name: "UserViewChapters",
  components: {
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState,
    SuawButton,
    SuawTable,
    SuawTableItem,
    SuawTextInput
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //Basic
      loadingQueriesCount: 0,
      resultUserView: {
        id: "",
        chapter_members: [
          // {
          //   id: "",
          //   user_id: "",
          //   chapter_id: "",
          //   chapter_member_role: "",
          //   chapter: {
          //     id: "",
          //     photo_url: "",
          //     title: "",
          //     slug: ""
          //   }
          // }
        ]
      },
      //Chapter
      isAssigning: false,
      loadingChaptersQueriesCount: 0,
      resultChapterList: [
        // {
        //   id: "",
        //   title: "",
        //   photo_url: "",
        //   place: {
        //     timezone: ""
        //   }
        // }
      ],
      filterChapterQuery: "",
      offsetChapter: 0,
      limitChapter: 4
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminChapterMembers,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultChapterList: {
      query: ChapterListForUserAdmin,
      // variables() {
      //   return {
      //     like: "%%"
      //   };
      // },
      loadingKey: "loadingChaptersQueriesCount",
      skip() {
        return !this.isAssigning;
      }
    }
  },
  computed: {
    //Basic Info
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    },
    //Chapter Info
    chapterTableItems() {
      return this.resultUserView.chapter_members && this.resultUserView.chapter_members.length > 0 ? this.chapterTableProps(this.resultUserView.chapter_members) : [];
    },
    isLoadingChapters() {
      return this.loadingChaptersQueriesCount > 0;
    },
    chaptersList() {
      return this.resultChapterList;
    },
    filteredChapters() {
      if (!this.filterChapterQuery) {
        return this.chaptersList.slice(this.offsetChapter, this.offsetChapter + this.limitChapter);
      }
      const lowerFilterQuery = this.filterChapterQuery.toLowerCase();
      return this.chaptersList
        .filter(x => x.title.toLowerCase().includes(lowerFilterQuery) || x.place.timezone.toLowerCase().includes(lowerFilterQuery))
        .slice(this.offsetChapter, this.offsetChapter + this.limitChapter);
    },
    hasNoChaptersData() {
      return !this.isLoadingChapters && this.filteredChapters.length === 0;
    },
    showPrevChapter() {
      return this.offsetChapter !== 0;
    },
    showNextChapter() {
      return this.filteredChapters.length === this.limitChapter;
    }
  },
  methods: {
    async promoteUser(item) {
      const { userId, id } = item;
      const result = await ChapterApi.promoteUser(userId, id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async demoteUser(item) {
      const { userId, id } = item;
      const result = await ChapterApi.demoteUser(userId, id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onPromoteDemoteClick(item) {
      if (item.role === "member") {
        const promoteUserResult = await this.promoteUser(item);
        if (!promoteUserResult.success) {
          return;
        }
        this.$apollo.queries.resultUserView.refetch();
        this.$root.$emit("universal-success-message", "Promotion successful.");
      } else if (item.role === "organizer") {
        const demoteUserResult = await this.demoteUser(item);
        if (!demoteUserResult.success) {
          return;
        }
        this.$apollo.queries.resultUserView.refetch();
        this.$root.$emit("universal-success-message", "Demotion successful.");
      }
    },
    toggleAssignOn() {
      this.isAssigning = true;
    },
    toggleAssignOff() {
      this.isAssigning = false;
    },
    async assignChapterMember(chapterId) {
      const userId = this.resultUserView.id;
      const result = await ChapterApi.assignChapterMember(userId, chapterId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onAssignClick(item) {
      const assignResult = await this.assignChapterMember(item.id);
      if (!assignResult.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "Assignment successful.");
    },
    async unassignChapterMember(userId, chapterId) {
      const result = await ChapterApi.unassignChapterMember(userId, chapterId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onUnassignClick(item) {
      const unassignResult = await this.unassignChapterMember(item.userId, item.id);
      if (!unassignResult.success) {
        return;
      }
      this.$apollo.queries.resultUserView.refetch();
      this.$root.$emit("universal-success-message", "Unassignment successful.");
    },
    chapterTableProps(chapterMemberItems) {
      return chapterMemberItems.map(x => ({
        id: x.chapter.id,
        avatar: x.chapter.photo_url ?? "  ",
        color: x.chapter.photo_url ? "picture" : "blue",
        contents: [
          {
            linkUrl: { name: "Chapter", params: { chapterId: x.chapter.id, chapterSlug: x.chapter.slug } },
            text: x.chapter.title,
            subtext: x.chapter_member_role
          }
        ],
        button: x.chapter_member_role === "member" ? "Promote" : x.chapter_member_role === "organizer" ? "Demote" : null,
        buttonCritical: "Unassign",
        userId: x.user_id,
        role: x.chapter_member_role
      }));
    },
    buildChapterItem(x) {
      return {
        id: x.id,
        avatar: x.photo_url ?? "  ",
        color: "blue",
        type: x.photo_url ? "picture" : "blue",
        contents: [
          {
            text: x.title,
            subtext: x.place.timezone
          }
        ],
        button: "Assign"
      };
    },
    handleChapterFilter(filterTerm) {
      if (!filterTerm) {
        this.filterChapterQuery = "";
        return;
      }
      this.filterChapterQuery = filterTerm;
    },
    onPrevChapter() {
      this.offsetChapter -= this.limitChapter;
    },
    onNextChapter() {
      this.offsetChapter += this.limitChapter;
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawInputGroup direction="column">
      <SuawHeading content="Joined Chapters" level="5" />
      <SuawTable v-if="chapterTableItems.length > 0">
        <SuawTableItem v-for="item in chapterTableItems" :key="item.id" :item="item" @message-click="onPromoteDemoteClick" @report-click="onUnassignClick" />
      </SuawTable>
      <SuawEmptyState v-else message="No Joined Chapters" />
    </SuawInputGroup>
    <SuawButton
      v-if="!isAssigning"
      icon-left="IconPlus"
      button-text="Assign Chapter"
      type="secondary-outline"
      class="suaw-button--pull-left"
      size="small"
      @click="toggleAssignOn"
    ></SuawButton>
    <SuawInputGroup v-else direction="column">
      <SuawButton
        icon-left="IconMinus"
        button-text="Do Not Assign Chapter"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="toggleAssignOff"
      ></SuawButton>
      <SuawHeading content="Available Chapters" level="5" />
      <SuawTextInput v-model="filterChapterQuery" placeholder="Filter Chapters" @input="handleChapterFilter" />
      <SuawEmptyState v-if="isLoadingChapters" message="Loading data..." />
      <SuawEmptyState v-else-if="hasNoChaptersData" message="No data" />
      <SuawInputGroup v-else field-one-size="0" field-two-size="0" :centered="true" direction="column">
        <SuawSection>
          <SuawButton
            v-if="showPrevChapter"
            icon-left="IconCircleChevronLeft"
            button-text="Prev"
            type="ghost"
            class="suaw-button--pull-left"
            size="small"
            @click="onPrevChapter"
          />
          <SuawButton
            v-if="showNextChapter"
            icon-left="IconCircleChevronRight"
            button-text="Next"
            type="ghost"
            class="suaw-button--pull-right"
            size="small"
            @click="onNextChapter"
          />
        </SuawSection>
        <SuawTable>
          <SuawTableItem v-for="u in filteredChapters" :key="u.id" :item="buildChapterItem(u)" @message-click="onAssignClick" />
        </SuawTable>
      </SuawInputGroup>
    </SuawInputGroup>
  </SuawInputGroup>
</template>

<style lang="css" scoped></style>
