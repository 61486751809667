<script>
import { SuawInputGroup, SuawHeading, SuawEmptyState, SuawTable, SuawTableItem, SuawDiscussionItem, SuawModal, SuawButton, SuawTextInput } from "@suaw/suaw-component-library";
import { GetUserViewForAdminComplaintsPost } from "../../operations.gql";
import * as DiscussionApi from "@/features/discussions/api.js";
import * as UserApi from "@/features/users/api.js";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewComplaintsPost",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState,
    SuawTable,
    SuawTableItem,
    SuawDiscussionItem,
    SuawModal,
    SuawButton,
    SuawTextInput
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserView: {
        id: "",
        complaints_posts_reporting: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   reported_user: {
          //     id: "",
          //     email: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_posts_resolution {
          //     id
          //   }
          // }
        ],
        complaints_posts: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   post {
          //     id
          //     posted_at
          //     body_json
          //     deleted_at
          //   }
          //   user {
          //     id
          //     email
          //     first_name
          //     last_name
          //     avatar_color
          //     avatar_url
          //     initials
          //   }
          //   reporting_user: {
          //     id: "",
          //     email: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_posts_resolution {
          //     id
          //   }
          // }
        ]
      },
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null,
      resolvingComplaints: [],
      resolutionComment: null
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminComplaintsPost,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    },
    complaintPostMadeTableItems() {
      return this.resultUserView.complaints_posts_reporting && this.resultUserView.complaints_posts_reporting.length > 0
        ? this.complaintPostMadeTableProps(this.resultUserView.complaints_posts_reporting)
        : [];
    },
    complaintPostReceivedTableItems() {
      return this.resultUserView.complaints_posts && this.resultUserView.complaints_posts.length > 0
        ? this.complaintPostReceivedTableProps(this.resultUserView.complaints_posts.filter(c => !this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    },
    complaintPostReceivedTableItemPosts() {
      this.distinctComplaintPosts;
      return this.resultUserView.complaints_posts && this.resultUserView.complaints_posts.length > 0
        ? this.complaintPostReceivedTablePostProps(this.resultUserView.complaints_posts)
        : [];
    },
    distinctComplaintPosts() {
      const distinctPostIds = this.resultUserView.complaints_posts
        .filter(c => !this.resolvingComplaints.find(r => r.id === c.id))
        .map(x => x.post.id)
        .filter((value, index, self) => self.indexOf(value) === index);

      const result = distinctPostIds.map(postId => {
        const complaint = this.resultUserView.complaints_posts.find(x => x.post.id === postId);
        return {
          id: complaint.post.id,
          posted_at: complaint.post.posted_at,
          body_json: complaint.post.body_json,
          deleted_at: complaint.post.deleted_at
        };
      });
      return result;
    },
    resolvingTableItems() {
      return this.resolvingComplaints.length > 0
        ? this.resolvingTableProps(this.resultUserView.complaints_posts.filter(c => this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    onCancelModal() {
      this.showModal = false;
    },
    complaintPostMadeTableProps(complaintPostMadeItems) {
      return complaintPostMadeItems.map(x => ({
        id: x.id,
        userId: x.user.id,
        avatar: x.user.avatar_url ?? x.user.initials,
        color: x.user.avatar_url ? "picture" : x.user.avatar_color,
        contents: [
          {
            text: `${x.user.first_name} ${x.user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: x.complaints_posts_resolution ? "Resolved" : "View Post"
      }));
    },
    onComplaintPostMadeViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    complaintPostReceivedTableProps(complaintPostReceivedItems) {
      return complaintPostReceivedItems.map(x => ({
        id: x.id,
        postId: x.post.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: x.complaints_posts_resolution ? "Resolved" : "View Complainer",
        buttonCritical: x.complaints_posts_resolution ? null : "Resolve"
      }));
    },
    complaintPostReceivedTablePostProps(complaintPostReceivedItems) {
      return complaintPostReceivedItems.map(x => ({
        id: x.post.id,
        userId: x.user.id,
        avatar: x.user.avatar_url ?? x.user.initials,
        color: x.user.avatar_url ? "picture" : x.user.avatar_color,
        contents: [
          {
            text: `${x.user.first_name} ${x.user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.post.body_json
          }
        ]
        // button: "View Complainer",
        // buttonCritical: x.complaints_posts_resolution ? "Resolved" : "Resolve"
      }));
    },
    onComplaintPostReceivedViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    onComplaintPostReceivedResolveClick(item) {
      this.resolvingComplaints.push(item);
    },
    complaintPostReceivedTableItemsByPost(postId) {
      return this.complaintPostReceivedTableItems.filter(x => x.postId === postId);
    },
    discussionDropdownType(post) {
      if (!this.$auth.isAuthenticated) return [];
      var postId = post.id;
      if (post.deleted_at !== null) {
        return [{ icon: "IconTrashFull", text: "Deleted", postId }];
      }
      return [{ icon: "IconTrashEmpty", text: "Delete", postId }];
    },
    async onRemovePost(postId) {
      const removePostResult = await DiscussionApi.removePost(postId);
      if (!removePostResult.success) {
        this.$root.$emit("universal-error-message", removePostResult.error);
      }
      this.showModal = false;
      this.modalTitle = null;
      this.$apollo.queries.resultUserView.refetch();
    },
    async onUnremovePost(postId) {
      const unremovePostResult = await DiscussionApi.unremovePost(postId);
      if (!unremovePostResult.success) {
        this.$root.$emit("universal-error-message", unremovePostResult.error);
      }
      this.showModal = false;
      this.modalTitle = null;
      this.$apollo.queries.resultUserView.refetch();
    },
    onPostDropdownItemClick(item) {
      if (item.icon == "IconTrashEmpty") {
        this.showModal = true;
        this.modalTitle = "Delete this post?";
        this.modalConfirmAction = () => this.onRemovePost(item.postId);
      } else if (item.icon == "IconTrashFull") {
        this.showModal = true;
        this.modalTitle = "UNDELETE this post?";
        this.modalConfirmAction = () => this.onUnremovePost(item.postId);
      }
    },
    resolvingTableProps(complaintReceivedItems) {
      return complaintReceivedItems.map(x => ({
        id: x.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: "Undo"
      }));
    },
    onUndoClick(item) {
      const i = this.resolvingComplaints.findIndex(c => c.id === item.id);
      if (i !== -1) {
        this.resolvingComplaints.splice(i, 1);
      }
    },
    async resolveComplaint() {
      var ids = this.resolvingComplaints.map(c => c.id);
      const result = await UserApi.resolveComplaintPost(ids, this.resolutionComment);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSaveResolution() {
      if (!this.resolutionComment) {
        this.$root.$emit("universal-error-message", "A resolution comment is required.");
        return;
      } else {
        const result = await this.resolveComplaint();
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        } else {
          this.$root.$emit("universal-success-message", "Complaint(s) resolved.");
          this.$apollo.queries.resultUserView.refetch();
          this.resolvingComplaints = [];
          this.resolutionComment = null;
        }
      }
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onCancelModal" />
    <SuawInputGroup v-if="complaintPostReceivedTableItems.length > 0" direction="column">
      <SuawHeading content="Post Complaints Received" level="5" />
      <SuawInputGroup v-for="post in distinctComplaintPosts" :key="post.id" direction="column">
        <SuawTable>
          <SuawTableItem
            v-for="item in complaintPostReceivedTableItemsByPost(post.id)"
            :key="item.id"
            :item="item"
            @message-click="onComplaintPostReceivedViewClick"
            @report-click="onComplaintPostReceivedResolveClick"
          />
        </SuawTable>
        <SuawDiscussionItem
          :id="post.id"
          :key="post.id"
          :card-text="post.body_json"
          :posted="formatDateAt(post.posted_at)"
          :response-number="-1"
          :show-discussion-likes="false"
          :dropdown-items="discussionDropdownType(post)"
          @dropdown-item-click="onPostDropdownItemClick"
        />
        <!-- <SuawSection section-style="border-shadow">
          <SuawParagraph :text="post.body_json" size="small">
            <template>
            Hiiii
            </template>
          </SuawParagraph> 
        </SuawSection> -->
      </SuawInputGroup>
    </SuawInputGroup>
    <SuawInputGroup v-if="resolvingComplaints.length > 0" direction="column">
      <SuawTextInput
        id="resolutionComment"
        v-model="resolutionComment"
        type="text"
        label="Post Complaint Resolution Comment"
        is-required
        placeholder="Explain how you resolved the Post complaint(s) listed below."
      />
      <SuawTable>
        <SuawTableItem v-for="item in resolvingTableItems" :key="item.id" :item="item" @message-click="onUndoClick" />
      </SuawTable>
      <SuawButton
        v-if="resolvingComplaints.length > 0"
        icon-left="IconDone"
        button-text="Save Resolution"
        type="primary"
        class="suaw-button--pull-left"
        size="medium"
        @click="onSaveResolution"
      ></SuawButton>
    </SuawInputGroup>
    <SuawHeading v-else content="No Post Complaints Received" level="6" />
    <SuawInputGroup v-if="complaintPostMadeTableItems.length > 0" direction="column">
      <SuawHeading content="Post Complaints Made" level="5" />
      <SuawTable>
        <SuawTableItem
          v-for="item in complaintPostMadeTableItems"
          :key="item.id"
          :item="item"
          @message-click="onComplaintPostMadeViewClick"
        />
      </SuawTable>
    </SuawInputGroup>
    <SuawHeading v-else content="No Post Complaints Made" level="6" />
  </SuawInputGroup>
</template>

<style lang="css" scoped></style>
